import React, { useEffect, useState } from 'react';
import { scroller } from "react-scroll";
import { Link } from 'react-router-dom';

import CardPrint from '../Card/CardPrint';
import OrderPrint from '../Card/OrderPrint';

import { connect } from 'react-redux';
import * as actions from '../../actions/pedidos';

import { FaCreditCard, FaPaypal } from 'react-icons/fa';
import moment from 'moment';
import { formatMoney } from '../../actions';
import StatusDetails from '../../containers/Pedidos/status-details';
import Table from '.';
import InputValor from "../Inputs/Valor";
import {TextoDados} from "../Texto/Dados";
import axios from "axios";
import {api, versao} from "../../config";
import {getHeaders} from "../../actions/localStorage";
import AlertGeral from "../Alert/Geral";

const OrdersTable = ({ cabecalho, dados, refreshState, refreshList, gridClass, admin, updateEntrega, cancelarPedido, updateSeen }) => {

  const [itemActive, setItemActive] = useState();
  const [printing, setPrinting] = useState("");
  const [isActive, setIsActive] = useState(null);

  useEffect(() => {
    setItemActive();
  }, [dados?.length]);

  const cancelOrder = async (pedido) => {
    if(window.confirm("Você realmente deseja cancelar este pedido?")){
			await cancelarPedido(pedido._id, admin.loja, (error) => {
        refreshList();
			});
		}
	}

	const confirmDelivery = (entrega) => {
		if(!entrega || !admin) return null;
		if(window.confirm("Deseja mesmo confirmar esta entrega? Lembre-se que uma notificação será enviada ao comprador!")){
			updateEntrega({ status: "Pedido Entregue", responsavel: admin.nome }, entrega._id, admin.loja, (error) => {
				refreshList();
        setItemActive();
			});
		}
	}

  async function handleUpdateSeen (pedidoId, loja, status) {
    await updateSeen(pedidoId, loja, status, () => {
      refreshList();
    });
  }

  async function handleSubmit(pedidoId, loja, shippingSender, shippingRecipient) {
      setIsActive({
          status: 'success',
          msg: "Configurações atualizada com sucesso!"
      });
      axios.put(`${api}/${versao}/api/entregas/admin/${pedidoId}/updateSender?loja=${loja}`, {shippingSender, shippingRecipient}, getHeaders())
          .then(response => {
          })
          .catch(e => console.log(e));


      // updateEntregaSender(pedidoId, loja, {field:value}, () => {
    //     console.log('updating...');
    //   refreshList();
    // });
      // update(categoryState, category._id, category.loja, (err, success) => {
      //     if(err) return notify(err.message, setNotification, "error");
      //
      //     notify("Categoria atualizada com sucesso!", setNotification)
      // })

  }

  function cartItems(cart) {
    const _cart = [];
    cart.forEach((item) => {
      _cart.push({
        "Produto": item.produto ? item.produto.titulo : item.upsell ? item.upsell.titulo : "UPSELL",
        "Preço Unid.": formatMoney(item.precoUnitario),
        "Quantidade": item.quantidade,
        "Preço Total": item?.coupon ? <span className="free">Grátis</span> : formatMoney(item.precoUnitario * item.quantidade),
        "Ações": item.upsell ? `/upsell/${item.upsell._id}` : `/produto/${item.produto ? item.produto._id : ""}`
      })
    });
    return <Table cabecalho={["Produto", "Preço Unid.", "Quantidade", "Preço Total"]} dados={_cart} gridClass="_cart-list" />
  }

  return (
    <div className={`Table${gridClass ? ` ${gridClass}` : ''} DynamicTable${refreshState ? ' refreshing' : ''}`}>

      <div className="head">
        { cabecalho.map((item, idx) => (item !== "Seen" && <div key={idx}>{item}</div> )) }
        { gridClass === "Orders" && <div>Situação</div>}
        { refreshList && (
          <button className="refreshButton" onClick={refreshList}>
            <i className="fas fa-redo"></i>
          </button>
        ) }
      </div>
        <AlertGeral aviso={isActive} />
      <div className="body">
        { dados.map((linha, idx) => !linha.pedido ? (<></>) : (
            <div className={`itemDelivery${itemActive === linha.ID ? ' active' : ''} item${linha.ID}`} key={linha.ID}>
              <div className={`item${linha["Seen"] === 1 ? ' novo' : ''} ${linha["manual"] ? ' manual' : ''}`} onClick={() => { setItemActive(itemActive === linha.ID ? '' : linha.ID); scroller.scrollTo(`item${linha.ID}`, { duration: 1200, delay: 0, offset: -100, smooth: "easeInOutQuart", }); }}>
                { !linha["manual"] && (linha["Seen"] === 1 ? <span className="newMessage">Novo</span> : <span className="newMessage">Visto</span>) }
                { (linha["manual"]) && <span className="newMessage">Manual</span> }
                { cabecalho.map((item, index) => item !== "Seen" && item !== "Valor" && <span key={index}>{linha[item] || ""}</span>) }
                { linha["Valor"] && (
                  <span className={`paymentInfo ${linha["Situação"] === "Cancelado" ? "cancelled" : ""}`}>
                    { linha["paymentMethod"] && (
                      <span className={`paymentMethod ${linha["paymentMethod"]}`}>
                        { linha["paymentMethod"] === "creditCard" && (<FaCreditCard />) }
                        { linha["paymentMethod"] === "PIX" && ("PIX") }
                        { linha["paymentMethod"] === "paypal" && (<FaPaypal />) }
                      </span>
                    ) }
                    <div className="price">
                      <span>{linha["Valor"]}</span>
                      <span>Liq. {linha["valueCart"]}</span>
                    </div>
                  </span>
                ) }
                { linha["Situação"] &&
                    <div className="status">
                      <span className={`pay${linha["Situação"].split("Pagamento").join("").split(" ").join("").split("PIX").join("Pix")}`}>
                        {linha["Situação"] || ""}
                      </span>
                    </div>}
              </div>
              <div className={`order-view${itemActive === linha.ID ? ' active' : ''}${refreshState ? ' refreshing' : ''}`}>
                { itemActive === linha.ID && (
                  <div className="order-wrapper">
                    <div className={`order-content${linha.pedido.cancelado ? ' cancelled' : ''}`}>
                      <div className="order-section">
                        <div className="head-info">
                          <h1>Pedido {linha.pedido.simpleId}</h1>
                          { linha.pedido.cancelado ? (
                            <h2 className="cancelled">
                              <i className="fas fa-ban"></i> Pedido Cancelado
                            </h2>) : (
                            <h2 className={`card-info ${linha.pedido.entrega.shippingMessageSelect ? 'added' : ''}`}>
                              <i className="fa fa-envelope"></i> Cartão { linha.pedido.entrega.shippingMessageSelect ? `adicionado` : `não adicionado`}
                            </h2>
                          )}
                        </div>
                        <label>Compra realizada em {moment(linha.pedido.createdAt).format("DD/MM/YYYY")} às {moment(linha.pedido.createdAt).format("HH:mm:ss")}</label>
                        <label>Deverá ser entregue em <span className="bold">{linha.pedido.entrega.shippingDate}</span> no Período da <span className="bold">{linha.pedido.entrega.shippingPeriod}</span></label>

                        <div className="list-items">
                          <div className="info">
                            <h3>Cliente</h3>
                            <span>{linha.pedido.cliente.nome} <Link to={`/cliente/${linha.pedido.cliente._id}`} className="viewClient">Ver mais</Link></span>
                          </div>
                          <div className="info">
                            <h3>Telefone</h3>
                            <span>{linha.pedido.cliente.telefones[0]}</span>
                          </div>

                          <div className="line"></div>

                          <div className="info">
                            <h3>Quem envia</h3>
                              <span>{linha.pedido.entrega.shippingSender}</span>
                              {/*<TextoDados chave="Quem envia?" valor={*/}
                              {/*    <InputValor noStyle value={linha.pedido.entrega.shippingSender} name="shippingSender" handleSubmit={(valor) => handleSubmit( linha.pedido.entrega._id, linha.pedido.loja, valor )} />*/}
                              {/*} />*/}
                          </div>
                          <div className="info">
                            <h3>Quem recebe</h3>
                            <span>{linha.pedido.entrega.shippingRecipient}</span>
                              {/*<TextoDados chave="Quem recebe?" valor={*/}
                              {/*    <InputValor noStyle value={linha.pedido.entrega.shippingRecipient} name="shippingRecipient" handleSubmit={(valor) => handleSubmit( linha.pedido.entrega._id, linha.pedido.loja, '',valor )} />*/}
                              {/*} />*/}
                          </div>

                          <div className="line"></div>

                          <div className="info">
                            <h3>Endereço de entrega</h3>
                            <span>{linha.pedido.entrega.endereco.local}, {linha.pedido.entrega.endereco.numero} - {linha.pedido.entrega.endereco.bairro}</span>
                          </div>

                          <div className="line"></div>

                          { cartItems(linha.pedido.carrinho) }
                          { linha.pedido.coupon && <div className="coupon-item">#{linha.pedido.coupon?.code}</div> }

                          <div className="line"></div>

                          <div className="values">
                            <div className="value">
                              <h4>Entrega</h4>
                              <h5>{formatMoney(linha.pedido.entrega.custo)}</h5>
                            </div>
                            <div className="value">
                              <h4>Produtos</h4>
                              <h5>{formatMoney(linha.pedido.carrinho?.reduce((previous, current) => previous + current?.precoUnitario, 0))}</h5>
                              { linha.pedido?.coupon && <span>{formatMoney(Number(-linha.pedido?.coupon?.value))} Cupom</span>}
                            </div>
                            <div className="value">
                              <h4>Total</h4>
                              <h5>{linha["Valor"]}</h5>
                              <span>Liq. {linha["valueCart"]}</span>
                            </div>
                            <div className={`paymentInfo ${linha["Situação"] === "Cancelado" ? "cancelled" : ""}`}>
                              { linha["paymentMethod"] && (
                                <div className={`paymentMethod ${linha["paymentMethod"]}`}>
                                  { linha["paymentMethod"] === "creditCard" && (<>
                                    <FaCreditCard /> <span>{linha.pagamento.parcelas}x no Cartão</span>
                                  </>) }
                                  { linha["paymentMethod"] === "PIX" && ("PIX") }
                                  { linha["paymentMethod"] === "paypal" && (<FaPaypal />) }
                                </div>
                              ) }
                            </div>
                          </div>

                        </div>
                      </div>

                      <div className="order-section">
                        <StatusDetails admin={admin} order={linha.pedido} payment={linha.pagamento} records={linha.registros} refreshList={refreshList} />
                      </div>
                    </div>

                    { printing === "order" && <OrderPrint data={linha.pedido} onClick={() => setPrinting("")} /> }
                    { printing === "card" && <CardPrint data={linha.pedido.entrega} onClick={() => setPrinting("")} /> }

                    <div className="button-actions">
                      <button className="whatsapp" title="Falar com o cliente" onClick={() => window.open(`https://web.whatsapp.com/send?phone=55${linha.cliente.whatsapp}`)}><i className="fab fa-whatsapp"></i></button>

                      { linha.pedido.entrega.shippingMessageSelect && <button className="view-card" title="Visualizar Cartão" onClick={() => setPrinting("card")}><i className="fa fa-envelope"></i></button>}
                      <button className="view-order" title="Visualizar resumo do pedido" onClick={() => setPrinting("order")}><i className="fa fa-print"></i></button>

                      <button className="seen" title={`Marcar como ${linha.pedido.seen === 1 ? 'visto' : 'não visto'}`} onClick={() => handleUpdateSeen(linha.pedido._id, linha.pedido.loja, linha.pedido.seen === 1 ? 2 : 1)}>
                        <i className={`fas fa-${linha.pedido.seen === 1 ? 'eye' : 'eye-slash'}`}></i>

                      </button>
                      { !linha.pedido.cancelado && <button className="cancel" title="Cancelar Pedido" onClick={() => cancelOrder(linha.pedido)}><i className="fa fa-times"></i></button> }
                      <button className="confirm" title="Confirmar Entrega" onClick={() => confirmDelivery(linha.pedido.entrega)}><i className="fa fa-check"></i></button>
                    </div>

                  </div>
                )}
              </div>
            </div>
        )) }
      </div>
    </div>
  )
};

export default connect(null, actions)(OrdersTable);